import { serviceLeadplus } from '@/utils/request'

export const getFormGroups = async (params?: any, signal?: AbortSignal) =>
    serviceLeadplus.get('/formgroups', {
        params,
        // signal,
    })

export const createFormGroup = async (
    data: {
        name: string
        forms?: string[]
    },
    params?: any,
    signal?: AbortSignal,
) =>
    serviceLeadplus.post('/formgroups', data, {
        params,
        // signal,
    })

export const updateFormGroupById = async (formGroupId: string, data: {
    name: string
    forms?: string[]
}, signal?: AbortSignal) =>
    serviceLeadplus.patch(
        `/formgroups/${formGroupId}`,
        data,
        {
            // signal,
        },
    )

export const deleteFormGroupById = async (formGroupId: string, signal?: AbortSignal) =>
    serviceLeadplus.delete(`/formgroups/${formGroupId}`, {
        // signal,
    })
