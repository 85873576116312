var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{attrs:{"closable":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.groupId ? 'Modifica gruppo' : 'Associa form a questo gruppo')+" ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"size":"large","aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"size":"large","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('ValidationObserver',{ref:"groupForm"},[_c('ValidationProvider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VsInput',{ref:"nameInput",staticClass:"vs-mb-6",attrs:{"label":_vm.groupId ? 'Modifica nome del gruppo di form' : 'Dai un nome al gruppo di form',"size":"large","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VsInputSearch',{ref:"nameInput",staticClass:"vs-mb-6",attrs:{"label":'Seleziona form o popup',"size":"large","caption":"Tutti i form selezionati verranno renderizzati con lo stesso script di integrazione del gruppo","error":errors.length > 0,"errorMessage":errors[0],"variant":"multiSelection","placeholder":"Cerca...","boundary":"viewport","options":_vm.availableForms.filter(function (el) { return !_vm.searchFilter || el.name.includes(_vm.searchFilter); }).map(function (el) {
                    return {
                        value: el.id,
                        label: el.name,
                    }
                }),"formatBadgeLabel":_vm.formatBadgeLabel,"debounce":500},on:{"search":function($event){_vm.searchFilter = $event}},model:{value:(_vm.forms),callback:function ($$v) {_vm.forms=$$v},expression:"forms"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }