














































































import { Component, Vue } from 'vue-property-decorator'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { getForms } from '@/api/leadplus/forms'
import { createFormGroup, updateFormGroupById } from '@/api/leadplus/formgroups'

@Component({
    name: 'CreateFormGroupModal',
})
export default class extends Vue {
    private groupId = ''
    private name = ''
    private searchFilter = ''
    private forms: any[] = []
    private availableForms: any[] = []
    private open = false
    public loading = false
    $refs!: any

    async save () {
        const valid = await this.$refs.groupForm.validate()
        if (!valid) return
        this.createGroup()
    }

    async createGroup () {
        this.loading = true

        try {
            let resp = null
            if (this.groupId) {
                resp = await updateFormGroupById(this.groupId, {
                    name: this.name,
                    forms: this.forms,
                })
            } else {
                resp = await createFormGroup({
                    name: this.name,
                    forms: this.forms,
                })
            }

            this.$root.$vsToast({
                heading: 'Gruppo di form salvato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })

            this.$emit('group-created', resp.data)
            this.closeModal()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la creazione del gruppo',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }

    async openModal (group?: {id: string, name: string, forms: string[]}) {
        this.loading = true
        this.groupId = ''
        this.name = ''
        this.forms = []
        this.availableForms = []
        if (group) {
            this.groupId = group.id
            this.name = group.name
            this.forms = group.forms
        }
        await this.getForms()
        this.open = true
        this.loading = false
    }

    public closeModal () {
        this.open = false
    }

    formatBadgeLabel (item: any) {
        return this.availableForms.find(el => el._id === item)?.name || 'Form non trovato'
    }

    async getForms () {
        const availableForms: any[] = []
        try {
            await this.loopCall(0, availableForms, getForms)
            this.availableForms = availableForms
        } catch (e) {
            console.log(e)
        }
    }

    async loopCall (page: number, values: any[], caller: any) {
        const resp = await caller({
            skip: page * 100,
            limit: 100,
        })
        values.push(...resp.data.docs)
        if (resp.data.docs.length < 100) return
        await this.loopCall(page + 1, values, caller)
    }
}
