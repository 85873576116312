













































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import CreateFormGroupModal from '@/modules/leadplus/components/CreateFormGroupModal/Index.vue'
import IntegrationFormGroupModal from '@/modules/leadplus/components/IntegrationFormGroupModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { deleteFormGroupById, getFormGroups } from '@/api/leadplus/formgroups'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'LeadplusGroups',
    components: {
        VsContainer,
        VsSectionHeader,
        VsConfirm,
        VsDropdownButton,
        CreateFormGroupModal,
        IntegrationFormGroupModal,
        VsListingCard,
    },
})
export default class extends Vue {
    emptyState = false
    loadingEmpty = false
    groups: any[] = []
    total = 0
    loading = false
    list: any = null
    lists = []

    $refs!: any
    filters = {
        search: '',
    }

    pagination: any = {
        page: 1,
        itemsPerPage: 5,
        orderBy: null,
    }

    get isMdViewport () {
        return AppModule.isMdViewport
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get campaignMessageOrderBy () {
        return [
            {
                label: this.$t('campaigns.index.orderBy.createdAtDesc'),
                value: '-_id',
            },
            {
                label: this.$t('campaigns.index.orderBy.createdAtAsc'),
                value: '_id',
            },
        ]
    }

    async beforeMount () {
        await this.checkHasGroup()
        this.getGroups()
    }

    async checkHasGroup () {
        this.loadingEmpty = true
        try {
            const resp = await getFormGroups({ limit: 1 })
            if (resp.data.count === 0) {
                this.emptyState = true
            }
        } catch (e) {
            console.log(e)
        }
        this.loadingEmpty = false
    }

    @Watch('filters', { deep: true, immediate: false })
    @Watch('pagination', { deep: true, immediate: false })
    private async getGroups () {
        this.loading = true
        try {
            const response = await getFormGroups(this.generateQuery())
            this.groups = response.data.docs
            this.total = response.data.count
            if (this.total > 0) this.emptyState = false
        } catch (e) {
            this.groups = []
            this.total = 0
        }
        this.loading = false
    }

    async deleteFormGroup (group: any) {
        try {
            await this.$refs.confirmFormDelete.openConfirm()
        } catch (e) {
            console.log(e)
            return
        }

        try {
            await deleteFormGroupById(group.id)
            this.$root.$vsToast({
                heading: 'Gruppo cancellato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione del gruppo',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }

        this.getGroups()
    }

    generateQuery () {
        const query: any = {
            limit: this.pagination.itemsPerPage,
            skip: (this.pagination.page - 1) * this.pagination.itemsPerPage,
            sort: this.pagination.orderBy || '-_id',
            // populate: 'formgroup,formlist',
        }

        const filter: any = {
            $and: [],
        }

        if (this.filters.search) {
            const checkForHexRegExp = /^[a-f\d]{24}$/i
            const $or: any[] = [
                { name: { $regex: this.filters.search, $options: 'i' } },
            ]
            if (checkForHexRegExp.test(this.filters.search)) {
                $or.push({ _id: this.filters.search })
            }
            filter.$and.push({
                $or,
            })
        }

        if (filter.$and.length > 0) {
            query.filter = JSON.stringify(filter)
        }
        return query
    }
}
